<div class="data-view-container">
	<div class="control-row">
		<div class="controls-left">
			<span data-html2canvas-ignore="true" *ngIf="this.dataSettings.analysisType === 'transactions' && gridMode && !dataLoading">
				Gross
				<mat-slide-toggle color="primary" class="toggle" [formControl]="netToggled" [checked]="netToggled.value"> </mat-slide-toggle>
				Net
			</span>
		</div>
		<div class="controls-right" *ngIf="!dataLoading && !hasError">
			<table-controls [menuSections]="tableControlsMenuSections" (emitGridModeToggled)="setGridMode($event)"> </table-controls>
		</div>
	</div>
	<ng-container *ngIf="!dataLoading && !hasError">
		<app-scrollable-data-table
			*ngIf="!gridMode && viewModel"
			[viewModel]="viewModel"
			[refreshingData]="refreshingData"
			[sortable]="isSortable"
			[clickableHeader]="this.dataSettings.analysisType === 'transactions' && userAvailablePermissions.has(PermissionId.readTransactions)"
			[clickableLabel]="this.dataSettings.analysisType === 'transactions' && userAvailablePermissions.has(PermissionId.readTransactions)"
			(itemClicked)="onItemClicked($event)"
			(sortedGroup)="saveGroupOrderData($event)">
		</app-scrollable-data-table>
		<app-analysis-grid *ngIf="gridMode" #grid [viewModel]="viewModel" [ngClass]="{ hidden: !showGrid }"></app-analysis-grid>
	</ng-container>
	<ng-container *ngIf="dataLoading || viewLoading">
		<div class="mb-5" [skeletonLoader]="true" [loaderOptions]="{ height: 45 }"></div>
		<div class="mb-5" [skeletonLoader]="true" [loaderOptions]="{ height: 45 }"></div>
		<div class="mb-5" [skeletonLoader]="true" [loaderOptions]="{ height: 45 }"></div>
		<div [skeletonLoader]="true" [loaderOptions]="{ height: 45 }"></div>
	</ng-container>
</div>
