import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnChanges, SimpleChanges, ElementRef, QueryList, ViewChildren, EventEmitter, Output, OnDestroy, AfterViewInit } from '@angular/core';
import { CellStyle, Group, ScrollableSummaryColumn, ScrollableTableClickEvent, ScrollableTableSortEvent } from '../../models/scrollable-data-table.model';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'app-scrollable-property-container',
	templateUrl: './scrollable-property-container.component.html',
	styleUrls: ['./scrollable-property-container.component.scss'],
})
export class ScrollablePropertyContainerComponent implements OnChanges, OnDestroy, AfterViewInit {
	private destroyed$: Subject<boolean>;
	@Input() group: Group;
	@Input() columnStyles: Record<string, CellStyle>;
	@Input() summaryColumn: ScrollableSummaryColumn;
	@Input() dateArray: string[];
	@Input() scrollLeft: number;
	@Input() clickableHeader: boolean;
	@Input() itemWidth: number;
	@Input() width: number;
	@Input() nestedLevel: number;
	@Input() sortable: boolean;
	@Input() headerProperties: string[];
	@Input() displayProperties: string[];

	@Input() set droppedItem(dropped: Subject<void>) {
		if (dropped) {
			this.dropped = dropped;
			this.dropped.pipe(takeUntil(this.destroyed$)).subscribe(() => this.updateScroll());
		}
	}
	private dropped: Subject<void>;

	@Output() itemClicked: EventEmitter<ScrollableTableClickEvent> = new EventEmitter();
	@Output() sortedGroup: EventEmitter<ScrollableTableSortEvent> = new EventEmitter();

	@ViewChildren('scroller') set scrollers(scrollers: QueryList<ElementRef>) {
		this._scrollers = scrollers;
		this.updateScroll();
	}
	private _scrollers: QueryList<ElementRef>;

	constructor(public sanitizer: DomSanitizer) {
		this.destroyed$ = new Subject<boolean>();
		this.columnStyles = {};
	}

	ngAfterViewInit(): void {
		if (this._scrollers) {
			this.updateScroll();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.scrollLeft && this._scrollers) {
			this.updateScroll();
		}

		if (changes.displayProperties && this._scrollers) {
			setTimeout(() => {
				this.updateScroll();
			}, 0);
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateScroll(): void {
		this._scrollers.forEach(scroller => {
			scroller.nativeElement.scrollLeft = this.scrollLeft;
		});
	}

	onClick(date: string, groupId: string, usdMode: boolean, property?: string, total?: boolean): void {
		const data: ScrollableTableClickEvent = {
			groupIds: groupId ? [groupId] : [],
			date,
			usdMode,
			total,
		};
		if (property) {
			data.groupIds.push(property);
		}
		this.itemClicked.emit(data);
	}

	drop(event: CdkDragDrop<string[]>): void {
		if (!this.group.properties[this.displayProperties[event.previousIndex]].sortable) {
			return;
		}
		let nonSortablePosition: number;
		for (let i: number = Math.min(event.previousIndex, event.currentIndex); i <= Math.max(event.previousIndex, event.currentIndex); i++) {
			if (!this.group.properties[this.displayProperties[i]].sortable) {
				nonSortablePosition = nonSortablePosition && event.previousIndex < event.currentIndex ? nonSortablePosition : i;
			}
		}
		moveItemInArray(this.displayProperties, event.previousIndex, nonSortablePosition ? nonSortablePosition - 1 : event.currentIndex);
		this.sortedGroup.emit({ ids: [], isProperty: true });
		setTimeout(() => this.updateScroll(), 0);
	}
}
