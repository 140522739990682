import { CustomMenuOption } from '@trovata/app/shared/models/custom-menu.model';
import { GenericOption } from '@trovata/app/shared/models/option.model';
import { Currency } from 'src/app/shared/models/currency.model';
import { Formatter } from 'src/app/shared/utils/formatter';
import { AnalysisDataRoot } from '../../reports/models/analysis.model';

export interface HeaderMarker {
	text?: string;
	icon?: string;
	iconClass?: string;
	class?: string;
	tooltip?: string;
}

export interface ScrollableSummaryColumn {
	displayValue: string;
	dataKey: string;
}

export interface ScrollableDataViewModel {
	// list of dates will appear in top scrollbar and will be used to select correct data from groupData obj
	dateArray: string[];
	dateLabels?: string[];
	summaryColumn?: ScrollableSummaryColumn;
	columnStyles: Record<string, CellStyle>;

	// order in which groups(i.e. accounts, institutions, etc.) will be displayed by id
	groupDisplayOrder: string[];

	groupData: GroupData;

	formatter: Formatter;

	// is added to cell width in case warning icons are being displayed
	extraCellWidth?: number;

	currencyConverted: Currency;
	headerProperties?: string[];
	displayProperties?: string[];
	analysisData?: AnalysisDataRoot;
}

export class GroupData {
	groups: Record<string, Group>;
	sortable: boolean = false;
	totals?: Group;
	balances?: Group;

	constructor() {
		this.groups = {};
	}
}

export class Group {
	groupInfo: string;
	groupOptions?: GenericOption[];
	disableGroupLabelClick?: boolean;
	groupId: string;
	headerMarkers: HeaderMarker[];
	// list of all properties to be displayed in the ScrollablePropertyContainerComponent component when expanded
	displayProperties: string[];
	// header properties are the names of the properties whose values will be displayed on expansion panel header
	headerProperties: string[];
	propertyStylesByDate: Record<string, CellStyle>;
	cellStylesByDate: Record<string, CellStyle>;
	toggleCellStyles: boolean;
	expanded: boolean;
	altChildrenExpanded: boolean;
	properties: Record<string, GroupPropData>;
	children?: string[];
	altChildren?: string[];
	childData?: GroupData;
	color?: string;
	customMenuOptions: CustomMenuOption[];
	disabledExpand?: boolean;
	placeholderValue?: string;
	sortable: boolean;
	sortableProperties: boolean;
	// reconciliation
	match?: { [propId: string]: { [date: string]: boolean } };
	// forecast
	icon?: string;
	tooltip?: string;
	// analysis
	tagOverlapWarning?: boolean;

	constructor(name: string) {
		this.groupInfo = name;
		this.expanded = false;
		this.sortable = false;
		this.sortableProperties = false;
		this.toggleCellStyles = true;
		this.properties = {};
		this.propertyStylesByDate = {};
		this.cellStylesByDate = {};
		this.children = [];
	}
}

export class GroupPropData {
	data: Record<string, string | boolean | number>;
	dataMetadata?: Record<string, { icon?: string; tooltip?: string }>;
	propertyStylesByDate: Record<string, CellStyle>;
	cellMarker: string;
	headerMarkers: HeaderMarker[];
	clickableLabel: boolean;
	clickableValues: boolean;
	sortable: boolean;
	isConvertedValue?: boolean;

	subProperty?: Record<string, GroupPropData>;
	subPropertyKey?: string;
	subPropertyStyles?: { [key: string]: string };
	highlighted?: boolean;
	icon?: string; // analysis
	hidden?: boolean;

	constructor(
		public propertyId: string,
		optionalProperties?: {
			cellMarker?: string;
			headerMarkers?: HeaderMarker[];
			clickableLabel?: boolean;
			clickableValues?: boolean;
			sortable?: boolean;
			isConvertedValue?: boolean;
			propertyStylesByDate?: Record<string, CellStyle>;
		}
	) {
		this.data = {};
		this.cellMarker = optionalProperties?.cellMarker;
		this.headerMarkers = optionalProperties?.headerMarkers;
		this.clickableLabel = optionalProperties?.clickableLabel;
		this.clickableValues = optionalProperties?.clickableValues;
		this.sortable = optionalProperties?.sortable;
		this.isConvertedValue = optionalProperties?.isConvertedValue;
		this.propertyStylesByDate = optionalProperties?.propertyStylesByDate || {};
	}
}

export enum ScrollableDataCustomStyle {
	backgroundColor = 'background-color',
	fontColor = 'color',
}

export type CellStyle = Partial<Record<ScrollableDataCustomStyle, string | undefined>>;

export class ScrollableTableClickEvent {
	date?: string;
	groupIds?: string[];
	group?: Group;
	usdMode?: boolean;
	total?: boolean;
	headerProperty?: string;
}

export interface ScrollableTableSortEvent {
	ids: string[];
	isProperty: boolean;
}

export interface GroupOptionSelectedEvent {
	option: GenericOption;
	groupIndex: number;
}
