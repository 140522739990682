export enum SkeletonLoaderUnit {
	px = 'px',
	rem = 'rem',
	em = 'em',
	pt = 'pt',
}

export interface SkeletonLoaderOptions {
	units?: SkeletonLoaderUnit;
	width?: number | string;
	height?: number | string;
	borderRadius?: number | string;
	loaderClass?: string;
	margin?: string;
	marginTop?: string;
}

export interface SkeletonLoaderConfig {
	loaderClass: string;
	loaderOptionClass: string;
	units: SkeletonLoaderUnit;
}

export const LoaderConfig: SkeletonLoaderConfig = {
	loaderClass: 'skeleton-loader',
	loaderOptionClass: 'skeleton-loader-options',
	units: SkeletonLoaderUnit.px,
};
