<div class="dialog-wrapper">
	<trovata-sticky-page-header [isDoneLoading]="true" [title]="title" [navigationButton]="'close'" (navigationEvent)="dialogRef.close()" [chip]="label">
	</trovata-sticky-page-header>
	<trovata-paginated-transactions-table
		[tqlExpression]="tqlExpression"
		[searchParameters]="searchParameters"
		[filter]="filter"
		[currencyOverride]="currencyOverride"
		[showGridButton]="true"
		[tagEditingEnabled]="!disableTagEdit"
		[tagOverlapMode]="tagOverlapMode"
		[transactionColumns]="transactionColumns">
	</trovata-paginated-transactions-table>
</div>
