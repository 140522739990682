import { Component, Input, OnChanges, SimpleChanges, ElementRef, EventEmitter, Output, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { Group, ScrollableTableClickEvent, ScrollableTableSortEvent, CellStyle, ScrollableSummaryColumn } from '../../models/scrollable-data-table.model';
import { MatExpansionPanel } from '@angular/material/expansion';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ScrollableChildGroupComponent } from '../scrollable-child-group/scrollable-child-group.component';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, takeUntil } from 'rxjs';
import { CustomMenuOptionEvent, CustomMenuOption } from '@trovata/app/shared/models/custom-menu.model';
import { smoothHeight } from '@trovata/app/shared/utils/animations';
import { GenericOption } from '@trovata/app/shared/models/option.model';

@Component({
	selector: 'app-scrollable-group-container',
	templateUrl: './scrollable-group-container.component.html',
	styleUrls: ['./scrollable-group-container.component.scss'],
	animations: [smoothHeight],
})
export class ScrollableGroupContainerComponent implements OnChanges, OnDestroy, AfterViewInit {
	private destroyed$: Subject<boolean>;
	@Input() group: Group;
	@Input() columnStyles: Record<string, CellStyle>;
	@Input() disableExpandProperties: boolean;
	@Input() disableMenus: boolean;
	@Input() summaryColumn: ScrollableSummaryColumn;
	@Input() dateArray: string[];
	@Input() width: number;
	@Input() scrollLeft: number;
	@Input() clickableHeader: boolean;
	@Input() clickableLabel: boolean;
	@Input() itemWidth: number;
	@Input() editable: boolean;
	@Input() sortable: boolean;
	@Input() set droppedItem(dropped: Subject<void>) {
		if (dropped) {
			this.dropped = dropped;
			this.dropped.pipe(takeUntil(this.destroyed$)).subscribe(() => {
				this.updateScroller();
			});
		}
	}
	dropped: Subject<void>;

	@Output() toggleCheck: EventEmitter<void>;
	@Output() sortedGroup: EventEmitter<ScrollableTableSortEvent>;
	@Output() itemClicked: EventEmitter<ScrollableTableClickEvent>;
	@Output() customMenuClicked: EventEmitter<CustomMenuOptionEvent<Group>>;
	@Output() groupOptionSelected: EventEmitter<GenericOption>;
	@Output() altChildrenIconClicked: EventEmitter<Group>;

	@ViewChild('scroller') set scroller(scroller: ElementRef) {
		this._scroller = scroller;
		this.updateScroller();
	}
	private _scroller: ElementRef;
	@ViewChild('expansionPanel') expansionPanel: MatExpansionPanel;

	constructor(public sanitizer: DomSanitizer) {
		this.destroyed$ = new Subject<boolean>();
		this.sortedGroup = new EventEmitter();
		this.itemClicked = new EventEmitter();
		this.toggleCheck = new EventEmitter();
		this.customMenuClicked = new EventEmitter();
		this.groupOptionSelected = new EventEmitter();
		this.altChildrenIconClicked = new EventEmitter<Group>();

		this.columnStyles = {};
	}

	ngAfterViewInit(): void {
		if (this.scroller) {
			this.updateScroller();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.group) {
			setTimeout(() => {
				this.updateScroller();
			}, 0);
		}

		if (changes.scrollLeft && this._scroller) {
			this.updateScroller();
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	private updateScroller(): void {
		this._scroller.nativeElement.scrollLeft = this.scrollLeft;
	}

	preventExpansion(group: Group, expansionPanel: MatExpansionPanel, event: Event): void {
		event.stopPropagation();
		if (group?.disabledExpand) {
			expansionPanel.close();
		}
	}

	onClick(
		clickableProperty: boolean,
		group: Group,
		date: string,
		usdMode: boolean,
		total: boolean = false,
		event: Event,
		expansionPanel?: MatExpansionPanel,
		childComponent?: ScrollableChildGroupComponent,
		isChild?: boolean,
		propertyId?: string,
		isValueClicked?: boolean
	): void {
		event.stopPropagation();
		if (clickableProperty) {
			const data: ScrollableTableClickEvent = {
				groupIds: group.groupId ? [group.groupId] : [],
				group: group,
				date,
				usdMode,
				total: total,
			};
			if (propertyId) {
				data.groupIds.push(propertyId);
			}
			if ((group.disabledExpand || !isChild) && !isValueClicked) {
				this.itemClicked.emit(data);
			} else {
				if (isChild) {
					this.childClick(data);
					return;
				}
				this.itemClicked.emit(data);
			}
		} else {
			if (!group?.disabledExpand) {
				if (isChild) {
					childComponent.toggleExpand();
				} else {
					expansionPanel.toggle();
				}
			}
		}
	}

	onAltChildrenIconClick(group: Group): void {
		this.altChildrenIconClicked.emit(group);
	}

	onGroupOptionSelect(option: GenericOption): void {
		this.groupOptionSelected.emit(option);
	}

	toggleExpand(): void {
		this.group.expanded = !this.group.expanded;
	}

	childClick(data: ScrollableTableClickEvent): void {
		if (data.groupIds) {
			data.groupIds.unshift(this.group.groupId);
		}
		this.itemClicked.emit(data);
	}

	drop(event: CdkDragDrop<string[]>): void {
		if (this.isDropAllowed(event)) {
			moveItemInArray(this.group.children, event.previousIndex, event.currentIndex);
			this.sortedGroup.emit({ ids: [this.group.groupId], isProperty: false });
			setTimeout(() => this.dropped?.next(), 0);
		}
	}

	private isDropAllowed(event: CdkDragDrop<string[]>): boolean {
		const disabledItemIndex: number = this.group.children.findIndex(groupId => !this.group.childData.groups[groupId].sortable);
		// Allow the drop only if the target index is less than or equal to the disabled item index
		return disabledItemIndex === -1 || event.currentIndex < disabledItemIndex;
	}

	childSortChanged(sortInfo: ScrollableTableSortEvent): void {
		sortInfo.ids.unshift(this.group.groupId);
		this.sortedGroup.emit(sortInfo);
	}

	onExpansionChange(expanded: boolean): void {
		this.group.expanded = expanded;
		this.toggleCheck.emit();
	}

	onMenuItemClicked(opt: CustomMenuOption, group: Group): void {
		const event: CustomMenuOptionEvent<Group> = {
			option: opt,
			row: group,
		};
		this.customMenuClicked.emit(event);
	}
}
