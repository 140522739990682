import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { TQLFieldsState, TQLFieldsStateModel, getStateSliceFromContext } from '../../store/state/tql-fields/tql-fields.state';
import { Observable, Subject, takeUntil } from 'rxjs';
import { TQLFieldContext, TQLFieldDict, TQLValuesDict } from '../../models/tql.model';
import { firstValidValueFrom } from '../../utils/firstValidValueFrom';

@Injectable({
	providedIn: 'root',
})
export class TQLFieldsFacadeService {
	@Select(TQLFieldsState.tqlFields) tqlFields$: Observable<TQLFieldsStateModel>;
	@Select(TQLFieldsState.tqlValues) tqlValues$: Observable<TQLValuesDict>;

	constructor() {}

	getTQLFieldsByContext(context: TQLFieldContext): Promise<TQLFieldDict> {
		const cleanupObservable$: Subject<boolean> = new Subject<boolean>();
		return new Promise((resolve, reject) => {
			try {
				this.tqlFields$.pipe(takeUntil(cleanupObservable$)).subscribe(resp => {
					if (resp && resp[getStateSliceFromContext(context)] !== null && resp[getStateSliceFromContext(context)] !== undefined) {
						cleanupObservable$.next(true);
						cleanupObservable$.complete();
						resolve(resp[getStateSliceFromContext(context)]);
					}
				});
			} catch (error) {
				reject(error);
			}
		});
	}

	async getTQLFieldsWithValuesByContext(context: TQLFieldContext) {
		const [fields, values]: [TQLFieldDict, TQLValuesDict] = await Promise.all([this.getTQLFieldsByContext(context), this.getAllTQLValues()]);
		for (const id in fields) {
			if (fields[id] && values[id]) {
				fields[id].options = values[id].values;
			}
		}
		return fields;
	}

	getAllTQLValues(): Promise<TQLValuesDict> {
		return firstValidValueFrom(this.tqlValues$);
	}
}
