import { Component, OnInit, Input, LOCALE_ID, Inject, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import {
	AnalysisType,
	AnalysisDisplaySettings,
	AnalysisDataSettings,
	AnalysisDataRoot,
	AnalysisBalanceValue,
	AnalysisTransactionValue,
	defaultAnalysisDisplaySettings,
	mapGroupTypeToTQLField,
	AnalysisOtherGroupValue,
	AnalysisTotalGroupValue,
} from 'src/app/features/reports/models/analysis.model';
import { UntypedFormControl } from '@angular/forms';
import { AnalysisDataViewModel } from 'src/app/features/reports/models/analysis-data-view.model';
import { MatDialog } from '@angular/material/dialog';
import { AnalysisGridComponent } from 'src/app/features/reports/components/analysis-grid/analysis-grid.component';
import { Cadence } from 'src/app/features/reports/models/cadence.model';
import { Currency, CurrencyDict } from 'src/app/shared/models/currency.model';
import { Observable, Subject } from 'rxjs';
import { Select } from '@ngxs/store';
import { SideNavSize } from 'src/app/shared/utils/content-size';
import { PermissionMap, PermissionId } from 'src/app/features/settings/models/feature.model';
import { CustomerFeatureState } from 'src/app/features/settings/store/state/customer-feature.state';
import { CurrencyFacadeService } from 'src/app/shared/services/facade/currency.facade.service';
import {
	Group,
	GroupData,
	ScrollableTableClickEvent,
	ScrollableTableSortEvent,
} from '@trovata/app/features/scrollable-data-table/models/scrollable-data-table.model';
import { TQLFieldsFacadeService } from '@trovata/app/shared/services/facade/tql-field.facade.service';
import { TQLValuesDict } from '@trovata/app/shared/models/tql.model';
import { TqlService } from '@trovata/app/shared/services/tql.service';
import { GenericOption } from '@trovata/app/shared/models/option.model';
import { DateTime } from 'luxon';
import { parseDateToLuxon } from '@trovata/app/shared/utils/date-format';
import { DialogAnimationService } from 'src/app/shared/services/dialog-animation.service';
import { TransactionsTableDialogComponent } from '@trovata/app/features/transactions/components/transactions-table-dialog/transactions-table-dialog.component';
import { TableControlsConstants, TableControlsMenuSection } from '@trovata/app/shared/models/table-controls.model';
import { formatLabelText } from 'src/app/shared/utils/date-format';
import { firstValidValueFrom } from '@trovata/app/shared/utils/firstValidValueFrom';
import { TransactionColumns, transactionPagColumns } from '@trovata/app/features/transactions/models/paginated-transactions-table-view-model';
import { PaginatedTableColumn } from '@trovata/app/shared/models/paginated-table-view-model';

export enum TagOverlapTransactionMode {
	none = 'none',
	showAll = 'showAll',
	tagOverlapOnly = 'showOnlyOverlapped',
}

@Component({
	selector: 'app-analysis-data-table',
	templateUrl: './analysis-data-table.component.html',
	styleUrls: ['./analysis-data-table.component.css'],
})
export class AnalysisDataTableComponent implements OnInit, OnChanges, OnDestroy {
	// state
	gridMode: boolean = false;
	netOnly: boolean = false;
	isConvertedCurrency: boolean = false;
	@Input() dataLoading: boolean;
	viewLoading: boolean;
	showGrid: boolean = false;
	hasError: boolean = false;
	noValidData: boolean = false;

	// data
	viewModel: AnalysisDataViewModel;
	private currencyDict: CurrencyDict;
	private valuesDict: TQLValuesDict;

	// form
	netToggled: UntypedFormControl = new UntypedFormControl(false);
	showGridTimeout: any;
	isSnackView: boolean;
	tableControlsMenuSections: TableControlsMenuSection[];

	@Input() analysisData: AnalysisDataRoot<AnalysisTransactionValue> | AnalysisDataRoot<AnalysisBalanceValue>;

	@Input() displaySettings: AnalysisDisplaySettings;
	@Input() dataSettings: AnalysisDataSettings;

	@Input() refreshingData: boolean;
	@Input() isSortable: boolean;
	@Input() isReport: boolean;

	@Input() disableTagEdit: boolean;

	@Output() onChangeOrder: EventEmitter<any> = new EventEmitter();
	@Output() dialogData: EventEmitter<number[] | AnalysisDataViewModel> = new EventEmitter();

	@ViewChild('grid') grid: AnalysisGridComponent;

	@Select(CustomerFeatureState.permissionIds) userAvailablePermissions$: Observable<PermissionMap>;
	userAvailablePermissions: PermissionMap;
	PermissionId: typeof PermissionId = PermissionId;

	private destroyed$: Subject<boolean>;

	constructor(
		@Inject(LOCALE_ID) public locale: string,
		public dialog: MatDialog,
		private currencyFacadeService: CurrencyFacadeService,
		private tqlFieldsFacadeService: TQLFieldsFacadeService,
		private dialogAnimationService: DialogAnimationService,
		private tqlService: TqlService
	) {
		this.destroyed$ = new Subject<boolean>();
		this.viewLoading = false;
		this.dataLoading = true;

		this.getPermissions();
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	private async getPermissions(): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				this.userAvailablePermissions = await firstValidValueFrom(this.userAvailablePermissions$);
				resolve();
			} catch {
				reject();
			}
		});
	}

	private async getValues(): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				this.valuesDict = await this.tqlFieldsFacadeService.getAllTQLValues();
				resolve();
			} catch {
				reject();
			}
		});
	}

	private async getCurrencyDict(): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				this.currencyDict = await this.currencyFacadeService.getCurrencyDict();
				resolve();
			} catch {
				reject();
			}
		});
	}

	ngOnInit(): void {
		this.netToggled.valueChanges.subscribe(netToggle => {
			this.netOnly = netToggle;
			this.loadViewModel();
		});
		this.setDisplaySettingsDefaults();
		this.buildTableControlsMenuSections();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ((changes.displaySettings || changes.analysisData) && this.analysisData && this.displaySettings) {
			this.hasError = false;
			if (this.gridMode) {
				this.viewLoading = true;
				this.showGrid = false;
			}
			this.buildTableControlsMenuSections();
			this.loadViewModel();
			this.displayGrid();
		}
		if (changes.disableTagEdit && changes.disableTagEdit.currentValue === undefined) {
			this.disableTagEdit = false;
		}
	}

	private setDisplaySettingsDefaults(): void {
		for (const key in defaultAnalysisDisplaySettings) {
			if (key in this.displaySettings) {
				continue;
			}

			this.displaySettings[key] = defaultAnalysisDisplaySettings[key];
		}
	}

	private buildTableControlsMenuSections(): void {
		this.tableControlsMenuSections = [
			{
				title: TableControlsConstants.gridModeOnly,
				gridModeOnly: true,
				rows: [
					{
						title: TableControlsConstants.convertedCurrency,
						checked: this.displaySettings.gridCurrencyColumn,
						onToggle: (state: boolean): void => {
							this.displaySettings.gridCurrencyColumn = state;
							this.loadViewModel();
						},
					},
					{
						title: TableControlsConstants.currencySymbols,
						description: TableControlsConstants.currencySymbolsDescription,
						checked: this.displaySettings.gridCurrencySymbols,
						onToggle: (state: boolean): void => {
							this.displaySettings.gridCurrencySymbols = state;
							this.loadViewModel();
						},
					},
				],
			},
		];
	}

	private async loadViewModel(): Promise<void> {
		if (this.analysisData && this.displaySettings) {
			const dataPromises: Promise<void>[] = [];
			if (!this.userAvailablePermissions) {
				dataPromises.push(this.getPermissions());
			}
			if (!this.valuesDict) {
				dataPromises.push(this.getValues());
			}
			if (!this.currencyDict) {
				dataPromises.push(this.getCurrencyDict());
			}
			if (dataPromises.length) {
				await Promise.all([dataPromises]);
			}
			this.noValidData = this.analysisData && !this.analysisData?.summary?.length;
			const currencyConverted: Currency = this.currencyDict[this.analysisData?.currencyConverted];
			this.viewModel = new AnalysisDataViewModel(
				this.analysisData,
				this.netOnly,
				this.valuesDict,
				this.isSortable,
				currencyConverted,
				this.locale,
				this.currencyDict,
				this.displaySettings
			);
			this.dialogData.emit(this.viewModel);
		}
	}

	setGridMode(enabled: boolean): void {
		if (enabled) {
			this.showGrid = false;
			this.viewLoading = true;
		}
		setTimeout(() => {
			this.gridMode = enabled;
			this.displayGrid();
		}, 200);
	}

	private displayGrid(): void {
		if (this.gridMode) {
			if (this.grid) {
				this.grid.autoSizeColumns();
			}
			if (this.showGridTimeout) {
				clearTimeout(this.showGridTimeout);
			}
			// this timeout is workaround for wijmo bug where columns get out of order
			setTimeout(() => {
				this.viewLoading = false;
				// timeout allows Wijmo to load grid while it is still hidden
				this.showGridTimeout = setTimeout(() => {
					this.showGrid = true;
				}, 2000);
			}, 10);
		} else {
			this.viewLoading = false;
			this.showGrid = true;
		}
	}

	onItemClicked(data: ScrollableTableClickEvent): void {
		if (this.dataSettings.analysisType !== AnalysisType.transactions || !this.userAvailablePermissions.has(PermissionId.readTransactions)) {
			return;
		}
		let type: string = 'Net';
		const lastGroupId: string = data.groupIds[data.groupIds.length - 1];
		if (['Credit', 'Debit', 'Net', 'Unique Total', 'Tag Delta'].includes(lastGroupId)) {
			type = lastGroupId;
			data.groupIds.pop();
		}
		let title: string = type + ' Transactions';

		let tqlQuery: object = this.analysisData.tql && this.analysisData.tql['expression'];
		if (!['Net', 'Unique Total', 'Tag Delta'].includes(type)) {
			tqlQuery = this.tqlService.addParameterToTQLExpression(tqlQuery, 'type', type.toLocaleLowerCase());
		}
		if (data.groupIds && data.groupIds.length > 0 && this.dataSettings.groupBy.length) {
			const groupTitles: string[] = [];

			data.groupIds.forEach((id: string, index: number) => {
				if (index >= this.dataSettings.groupBy.length) {
					throw new Error(`Index "${index}" for groupId "${id}" is out of bounds`);
				}

				const groupingName: string = this.dataSettings.groupBy[index];
				const paramType: string = mapGroupTypeToTQLField(groupingName);
				const allValues: GenericOption[] = this.valuesDict[paramType].values;
				if (id === AnalysisOtherGroupValue) {
					tqlQuery = this.tqlService.addParameterToTQLExpression(
						tqlQuery,
						paramType,
						allValues.map((value: GenericOption) => value.id),
						{ not: true }
					);

					groupTitles.push(`No ${this.viewModel.getGroupTypeDisplay(groupingName)}`);
				} else if (id !== AnalysisTotalGroupValue) {
					tqlQuery = this.tqlService.addParameterToTQLExpression(tqlQuery, paramType, id);
					const groupTitle: string = allValues.find((value: GenericOption) => value.id === id)?.displayValue;
					if (groupTitle) {
						groupTitles.push(groupTitle);
					} else {
						console.warn('Could not find group title for id', id);
					}
				}
			});

			title += ` Grouped By ${groupTitles.join(', ')}`;
		}

		let startDate: DateTime;
		let endDate: DateTime;
		if (!data.total) {
			startDate = data.date ? parseDateToLuxon(data.date) : DateTime.fromISO(this.analysisData.startDate);
			endDate = startDate;
			if (this.dataSettings.cadence === Cadence.weekly) {
				endDate = startDate.plus({ days: this.analysisData.excludeWeekends ? 4 : 6 });
			} else if (this.dataSettings.cadence === Cadence.monthly) {
				endDate = startDate.plus({ months: 1 }).minus({ days: 1 });
			} else if (this.dataSettings.cadence === Cadence.quarterly) {
				endDate = startDate.plus({ quarters: 1 }).minus({ days: 1 });
			}
		} else {
			startDate = DateTime.fromISO(this.analysisData.startDate);
			endDate = DateTime.fromISO(this.analysisData.endDate);
		}

		if (this.analysisData.startDate && startDate < DateTime.fromISO(this.analysisData.startDate)) {
			startDate = DateTime.fromISO(this.analysisData.startDate);
		}
		if (this.analysisData.endDate && endDate > DateTime.fromISO(this.analysisData.endDate)) {
			endDate = DateTime.fromISO(this.analysisData.endDate);
		}

		tqlQuery = this.tqlService.addDatesToTQLExpression(tqlQuery, startDate, endDate);
		let mode: TagOverlapTransactionMode = TagOverlapTransactionMode.none;
		if (type === 'Tag Delta') {
			mode = TagOverlapTransactionMode.tagOverlapOnly;
		} else if (type === 'Unique Total' || data.group?.groupId === AnalysisTotalGroupValue) {
			mode = TagOverlapTransactionMode.showAll;
		}

		this.showTransactions(tqlQuery, title, formatLabelText([startDate.toISODate(), endDate.toISODate()]), mode);
	}

	private showTransactions(tqlExpression: object, title: string, label: string, mode: TagOverlapTransactionMode): void {
		// deep copy of columns
		// other ways result in a reference to the same object
		// which causes the columns to be modified for the rest of the app
		const columns: PaginatedTableColumn[] = transactionPagColumns.map(
			(col: PaginatedTableColumn) => new PaginatedTableColumn(col.colDef, col.type, col.options)
		);

		if (this.analysisData.tagOverlap?.tagOverlapAnalysis?.affectedTags?.length) {
			const warningColumn: PaginatedTableColumn = columns.find((col: PaginatedTableColumn) => col.colDef === TransactionColumns.overlapWarning);
			warningColumn.included = true;
		}

		this.dialogAnimationService.open(TransactionsTableDialogComponent, {
			autoFocus: false,
			disableClose: true,
			width: `calc(100vw - ${SideNavSize()}px)`,
			minWidth: `calc(100vw - ${SideNavSize()}px)`,
			position: { top: '0', right: '0' },
			height: '100%',
			panelClass: 'side-dialog-container',
			restoreFocus: false,
			animation: {
				to: 'left',
				incomingOptions: { keyframeAnimationOptions: { easing: 'ease-in-out', duration: 200 } },
				outgoingOptions: { keyframeAnimationOptions: { easing: 'ease-in-out', duration: 200 } },
			},
			data: {
				title,
				label,
				tqlExpression,
				disableTagEdit: this.disableTagEdit,
				tagOverlapMode: mode,
				requestedColumns: columns,
			},
		});
	}

	saveGroupOrderData(sortingEvent?: ScrollableTableSortEvent): void {
		this.displaySettings.userOrdered = this.displaySettings.userOrdered || { order: [] };
		if (!sortingEvent?.ids?.length) {
			this.displaySettings.userOrdered.order = this.viewModel.groupDisplayOrder;
		} else {
			let parent: GroupData | Group = this.viewModel.groupData;
			let parentOrder: any = this.displaySettings.userOrdered;
			sortingEvent.ids.forEach((id, idx) => {
				parentOrder[id] = parentOrder[id] || { order: [] };
				const child: Group = parent['groups'] ? (<GroupData>parent).groups[id] : (<Group>parent).childData.groups[id];
				if (idx + 1 === sortingEvent.ids.length) {
					let order: string[];
					if (sortingEvent.isProperty) {
						order = child.displayProperties.map(prop => child.properties[prop].propertyId);
					} else {
						order = child['children'];
					}
					parentOrder[id].order = order;
				} else {
					parentOrder = parentOrder[id];
					parent = child;
				}
			});
		}
		this.onChangeOrder.emit(this.displaySettings.userOrdered);
	}
}
