<div *ngIf="size != 3" [className]="size == 1 ? 'loader small' : 'loader medium'">
	<div class="loadt"></div>
	<div class="loadt"></div>
	<div class="loadt"></div>
	<div></div>
	<div class="loadt"></div>
	<div></div>
	<div></div>
	<div class="loadt"></div>
	<div></div>
</div>

<div *ngIf="size == 3" class="big-container" [style.width.px]="customWidth" [style.height.px]="customHeight">
	<div *ngIf="size == 3" class="loader medium">
		<div class="loadt"></div>
		<div class="loadt"></div>
		<div class="loadt"></div>
		<div></div>
		<div class="loadt"></div>
		<div></div>
		<div></div>
		<div class="loadt"></div>
		<div></div>
	</div>
</div>
