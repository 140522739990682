import { DateTime, Settings } from 'luxon';

export const getDateFormatPattern = (locale?: string, forceDoubleDigitFormat?: boolean, toUpperCase?: boolean, delimiter?: DateFormatDelimiter): string => {
	if (!locale) {
		locale = Settings.defaultLocale;
	}
	const getPatternForPart = (part: Intl.DateTimeFormatPart) => {
		switch (part.type) {
			case 'day':
				if (!forceDoubleDigitFormat) {
					return 'd'.repeat(part.value.length);
				} else {
					return 'dd';
				}

			case 'month':
				if (!forceDoubleDigitFormat) {
					return 'M'.repeat(part.value.length);
				} else {
					return 'MM';
				}
			case 'year':
				return 'y'.repeat(part.value.length);
			case 'literal':
				if (delimiter === DateFormatDelimiter.dash) {
					return '-';
				} else if (delimiter === DateFormatDelimiter.slash) {
					return '/';
				} else {
					return part.value;
				}
			default:
				return '';
		}
	};

	let formatString: string = DateTime.fromISO('2024-01-01').setLocale(locale).toLocaleParts(DateTime.DATE_SHORT).map(getPatternForPart).join('');

	if (toUpperCase) {
		formatString = formatString.toUpperCase();
	}

	return formatString;
};

// this is mainly used for chart and data table date parsing where the date format is x/x/xx or x/x/xxxx
// With using local date formats, new Date() cannot distinguish which is the month and the days in the example strings above
export function parseDateToLuxon(dateString: string): DateTime {
	const localFormat: string = getDateFormatPattern(null, true);
	const possibleFormats: string[] = [
		localFormat.replace('MM', 'M').replace('dd', 'd').replace('yyyy', 'yy'),
		localFormat.replace('MM', 'M').replace('dd', 'd'),
		'yyyy-M-d',
		'LLL-yyyy',
		'MMM-yyyy',
		'MMM yyyy',
	];

	let parsedDate: DateTime;
	for (const format of possibleFormats) {
		parsedDate = DateTime.fromFormat(dateString, format);

		// Check if parsing was successful
		if (parsedDate.isValid) {
			break;
		}
	}

	return parsedDate;
}

export enum DateFormatDelimiter {
	slash = 'slash',
	dash = 'dash',
}

export function formatLabelText(dates: string[]): string {
	if (dates.length === 0) {
		return '';
	}

	const lastDateIndex: number = dates.length - 1;
	if (dates[0] === dates[lastDateIndex]) {
		return parseDateToLuxon(dates[0])?.toLocaleString(DateTime.DATE_FULL) ?? dates[0];
	}

	if (DateTime.fromISO(dates[0]).toMillis() > DateTime.fromISO(dates[lastDateIndex]).toMillis()) {
		[dates[0], dates[lastDateIndex]] = [dates[lastDateIndex], dates[0]];
	}

	const fromDate: DateTime | null = parseDateToLuxon(dates[0]);
	const toDate: DateTime | null = parseDateToLuxon(dates[lastDateIndex]);

	if (!fromDate || !toDate) {
		return [dates[0], dates[lastDateIndex]].join(' - ');
	}

	if (fromDate.year === toDate.year && fromDate.month === toDate.month) {
		return `${fromDate.toFormat('d')} - ${toDate.toFormat('d')} ${toDate.toFormat('MMMM, yyyy')}`;
	} else if (fromDate.year === toDate.year) {
		return `${fromDate.toFormat('MMM d')} - ${toDate.toFormat('MMM d, yyyy')}`;
	} else {
		return `${fromDate.toLocaleString(DateTime.DATE_SHORT)} - ${toDate.toLocaleString(DateTime.DATE_SHORT)}`;
	}
}
