import { Account } from './account.model';
import { Tag } from './tag.model';
import { Formatter } from 'src/app/shared/utils/formatter';
import { CurrencyDict } from 'src/app/shared/models/currency.model';
import { AccountTargetV3 } from 'src/app/shared/models/account-target.model';
import { DisplayColumn, DisplayColumnMenu, getTransactionDetailsColumns, transactionGlTagColumns } from 'src/app/shared/models/columns.model';
import { TitleCasePipe } from '@angular/common';
import { GLTag } from './glTag.model';
import { GLCode } from './gl-code.model';

export class GetTransactionsResponse {
	transactions: Transaction[];
	totalTransactions: number;
	currencySummary: CurrencySummary;
}

export class Transaction {
	transactionId: string;
	date: Date;
	amount: number;
	amountConverted: number;
	type: string;
	description: string;
	descriptionDetail: string;
	category: string;
	currency: string;
	currencyConverted: string;
	status: string;
	account: Account;
	isIntraday: string;
	tags: Tag[];
	metadata: Metadata;
	metadataDisplay: MetadataDisplay[];
	isSelected?: boolean;
	glTag: GLTag | string | any;
	glCode1?: GLCode;
	glCode2?: GLCode;
	memo: string;
	bankReference: string;
	overlappedTags: string[];

	displayAmount() {
		if (this.type === 'DEBIT') {
			return '-' + this.amount;
		}
		return this.amount;
	}
	displayUSDAmount() {
		if (this.type === 'DEBIT') {
			return '-' + this.amount;
		}
		return this.amount;
	}
}

export class ManualTransaction {
	date: string;
	amount: number;
	description: string;
	memo: string;
	accountId: string;
}

export class Metadata {
	baiType: {
		typeCode: string;
		summaryTypeCode: string;
		description: string;
		shortDescription: string;
		groupCode: string;
		groupDescription: string;
		productGroupCode: string;
		btrsTypeCode: string;
		germanTypeCode: string;
		swiftTypeCode: string;
	};
	narrativeText: {
		yourRef: string;
		paidTo: string;
		bOCustomer: string;
		bOBank: string;
		remark: string;
		recGfp: string;
		recFrom: string;
		acctParty: string;
		ultiBene: string;
		valDate: string;
	};
}

export class MetadataDisplay {
	blockType: string;
	blockValues: BlockValue[];
}

export class BlockValue {
	displayKey: string;
	displayValue: string;
}

export class TransactionGrid {
	transactionGridRows: TransactionGridRow[];
	displayColumnsMenu: DisplayColumnMenu[];
	convertedCurrency: string;

	includedTags: Tag[];
	metadataDisplay: string[];

	constructor(
		private transactions: Transaction[],
		private accounts: AccountTargetV3[],
		private currencyDict: CurrencyDict,
		private includeSingleTagColumn: boolean,
		private formatting: boolean,
		private tagSortingOrder?: string[]
	) {
		this.displayColumnsMenu = [];
		this.includedTags = [];
		this.generateGridRows();
		this.setFirstColumnMenu();
		this.setGLTagColumnMenu();
		this.setTagColumnMenu();
	}

	private generateGridRows() {
		this.includedTags = [];
		this.metadataDisplay = [];
		this.transactionGridRows = this.transactions.map((trxn: Transaction) => {
			if (!this.convertedCurrency) {
				this.convertedCurrency = trxn.currencyConverted;
			}
			const row: TransactionGridRow = new TransactionGridRow(trxn, this.accounts, this.currencyDict, this.formatting);
			const singleTagColumnArr = [];
			this.sortTags(trxn.tags).forEach(tag => {
				row[tag.tagId] = tag.tagTitle;
				singleTagColumnArr.push(tag.tagTitle);
				if (!this.includedTags.find(incTag => incTag.tagId === tag.tagId)) {
					this.includedTags.push(tag);
				}
			});

			row.singleTagColumn = singleTagColumnArr.join(', ');

			trxn.metadataDisplay.forEach(metadata => {
				let metadataDisplayColumn: DisplayColumnMenu = this.displayColumnsMenu.find(c => c.name === metadata.blockType);
				if (!metadataDisplayColumn) {
					metadataDisplayColumn = new DisplayColumnMenu();
					metadataDisplayColumn.name = metadata.blockType;
					metadataDisplayColumn.columns = [];
				}

				metadata.blockValues.forEach((item: BlockValue) => {
					if (!this.metadataDisplay.includes(item.displayKey) && item.displayKey !== 'Description') {
						this.metadataDisplay.push(item.displayKey);
						metadataDisplayColumn.columns.push({
							header: item.displayKey,
							binding: item.displayKey,
						});
					}
					row[item.displayKey] = item.displayValue;
				});
				if (this.displayColumnsMenu.findIndex(c => c.name === metadataDisplayColumn.name) < 0) {
					this.displayColumnsMenu.push(metadataDisplayColumn);
				}
			});
			return row;
		});

		this.includedTags = this.sortTags(this.includedTags);
	}

	private setGLTagColumnMenu() {
		const menuColumn: DisplayColumnMenu = {
			name: 'G/L Tag',
			columns: transactionGlTagColumns,
		};
		this.displayColumnsMenu.push(menuColumn);
	}

	private setFirstColumnMenu() {
		const menuColumn: DisplayColumnMenu = {
			name: 'Transaction Details',
			columns: getTransactionDetailsColumns(this.convertedCurrency),
		};
		this.displayColumnsMenu.unshift(menuColumn);
	}

	private setTagColumnMenu() {
		const displayMenuTags: DisplayColumn[] = [];
		this.includedTags.forEach(tag => {
			const displayTag: DisplayColumn = {
				header: tag.tagTitle,
				binding: tag.tagId,
			};
			displayMenuTags.push(displayTag);
		});

		if (this.includeSingleTagColumn) {
			const singleTagMenu: DisplayColumn = {
				header: 'Tags',
				binding: 'singleTagColumn',
			};
			displayMenuTags.push(singleTagMenu);
		}

		const i: number = this.displayColumnsMenu.findIndex(c => c.name === 'Tags');
		if (i >= 0) {
			this.displayColumnsMenu[i].columns = displayMenuTags;
		} else {
			const tagColumn: DisplayColumnMenu = {
				name: 'Tags',
				columns: displayMenuTags,
			};
			this.displayColumnsMenu.push(tagColumn);
		}
	}

	private sortTags(tags: Tag[]): Tag[] {
		if (!tags?.length) {
			return [];
		}
		tags = [...tags];

		if (!this.tagSortingOrder) {
			return tags;
		}

		tags.sort((tagA, tagB) => this.getTagSortingRank(tagA) - this.getTagSortingRank(tagB));
		return tags;
	}

	private getTagSortingRank(tag: Tag): number {
		if (!this.tagSortingOrder) {
			return 0;
		}

		const rank: number = this.tagSortingOrder.indexOf(tag.tagId);

		// Knock any unidentified tags to the end
		return rank < 0 ? this.tagSortingOrder.length : rank;
	}

	filterSingleTagColumn(tagIds: string[]): void {
		this.transactionGridRows.forEach((row: TransactionGridRow) => {
			// Filter from `includedTags` so we don't have to sort again
			row.singleTagColumn = this.includedTags
				.filter((tag: Tag) => tagIds.includes(tag.tagId) && row.transaction.tags?.some((rowTag: Tag) => rowTag.tagId === tag.tagId))
				.map((tag: Tag) => tag.tagTitle)
				.join(', ');
		});
	}
}

export class TransactionGridRow {
	transaction: Transaction;
	date: Date;
	amount: string;
	amountConverted: string;
	type: string;
	description: string;
	descriptionDetail: string;
	category: string;
	convertedCurrency: string;
	currency: string;
	accountNumber: string;
	accountName: string;
	institutionName: string;
	metatdata: Metadata;
	glTag: string;
	glTagDescription: string;
	debitGlCode: string;
	creditGlCode: string;
	memo: string;
	status: string;
	isIntraday: string;
	singleTagColumn: string;
	bankReference: string;

	formatter: Formatter = new Formatter();

	constructor(
		transaction: Transaction,
		accounts: AccountTargetV3[],
		currencyDict: CurrencyDict,
		private formatting: boolean
	) {
		this.transaction = transaction;
		this.date = (transaction.date as any).substring(0, 10);
		this.description = transaction.description;
		this.descriptionDetail = transaction.descriptionDetail;
		this.currency = transaction.currency;
		this.convertedCurrency = transaction.currencyConverted;
		this.accountNumber = transaction.account.accountNumber;
		this.institutionName = transaction.account.institutionNickname;
		this.category = transaction.category;
		this.metatdata = transaction.metadata;
		this.memo = transaction.memo;
		this.isIntraday = transaction?.isIntraday?.toString()?.toUpperCase();
		this.bankReference = transaction.bankReference;
		// currently account nickname will only return in accounts call, this will apply the account nickname to the transaction row
		const fullAccount = accounts?.find(acc => acc.accountId === transaction.account.accountId);
		if (fullAccount) {
			this.accountName = fullAccount.nickname ? fullAccount.nickname : fullAccount.name;
		}
		if (transaction.status) {
			const titlecase: TitleCasePipe = new TitleCasePipe();
			this.status = titlecase.transform(transaction.status);
		}

		if (transaction?.glTag?.glTagId) {
			this.glTag = transaction.glTag.title;
			this.glTagDescription = transaction.glTag.description;
			this.debitGlCode = transaction.glCode1?.name;
			this.creditGlCode = transaction.glCode2?.name;
		}
		if (transaction.type === 'DEBIT') {
			this.type = 'DR';
			if (this.formatting) {
				this.amount = this.formatter.currencyWijmoCellFormat(-transaction.amount, currencyDict[transaction.currency]);
				this.amountConverted = this.formatter.currencyWijmoCellFormat(-transaction.amountConverted, currencyDict[transaction.currencyConverted]);
			} else {
				this.amount = (-transaction.amount)?.toString();
				this.amountConverted = (-transaction.amountConverted)?.toString();
			}
		} else if (transaction.type === 'CREDIT') {
			this.type = 'CR';
			if (this.formatting) {
				this.amount = this.formatter.currencyWijmoCellFormat(+transaction.amount, currencyDict[transaction.currency]);
				this.amountConverted = this.formatter.currencyWijmoCellFormat(+transaction.amountConverted, currencyDict[transaction.currencyConverted]);
			} else {
				this.amount = (+transaction.amount)?.toString();
				this.amountConverted = (+transaction.amountConverted)?.toString();
			}
		} else {
			this.type = transaction.type;
			if (this.formatting) {
				this.amount = this.formatter.currencyWijmoCellFormat(+transaction.amount, currencyDict[transaction.currency]);
				this.amountConverted = this.formatter.currencyWijmoCellFormat(+transaction.amountConverted, currencyDict[transaction.currencyConverted]);
			} else {
				this.amount = (+transaction.amount)?.toString();
				this.amountConverted = (+transaction.amountConverted)?.toString();
			}
		}
	}
}

export interface TransactionTagResponse {
	batchSize: number;
	nextBatchId: string;
	tagObjects: { tagId: string }[];
	totalBatchSize: number;
	totalTags: number;
}

export class TransactionsSummary {
	currencySummary: CurrencySummary;
	totalTransactions: number;
}

export class TransactionsPagePreferences {
	showFullAcctNumbers: boolean;
}

export interface CurrencySummary {
	converted: number;
	currencyConverted: string;
}

export interface ManualTransactionDeleteBody {
	godDelete: boolean;
	isAll: boolean;
	transactionIds: string[];
	searchBody: TransactionsRequestBody;
}

export interface TransactionsRequestBody {
	q?: string;
	positionType?: string[];
	from?: number;
	size?: number;
	endDate?: string;
	startDate?: string;
	tagVerbosity?: 'full' | 'name' | 'id';
	fullAccountNumbers?: boolean;
	includeCurrencySummary?: boolean;
	currency?: string[];
	sort?: string[];
	currencyOverride?: string;
	tagId?: string[];
	glTagId?: string[];
	accountId?: string[];
	institutionId?: string;
	type?: string[];
	institutionType?: 'MANUAL' | 'NONMANUAL' | 'ALL';
	tql?: Object;
	includeTransactions?: string[];
	tagOverlapOnly?: boolean; // used in analysis reports to show only transactions affected by tag overlapping
}

export interface TransactionsSummaryRequestBody {
	q?: string;
	from?: number;
	size?: number;
	endDate?: string;
	startDate?: string;
	fullAccountNumbers?: boolean;
	includeCurrencySummary?: boolean;
	currencyOverride?: string;
	tagId?: string[];
	institutionId?: string;
	institutionType?: 'MANUAL' | 'NONMANUAL' | 'ALL';
	tql?: object;
	tagOverlapOnly?: boolean; // used in analysis reports to show only transactions affected by tag overlapping
}
