export const ContentSize: () => number = () => {
	const sideNavContent = document.querySelector<HTMLElement>('mat-sidenav-content[role=main]');
	return sideNavContent.offsetWidth;
};

export const SideNavSize: () => number = () => {
	const sideNav = document.querySelector<HTMLElement>('mat-sidenav[role=navigation]');
	return sideNav?.offsetWidth || 0;
};

export const AddCustomMargin: (customClass: CustomMarginClasses) => void = (customClass: CustomMarginClasses) => {
	const mainContainer = document.getElementById('mainContent').firstElementChild.classList;
	mainContainer.add(customClass);
};

export const RemoveCustomMargin: (customClass: CustomMarginClasses) => void = (customClass: CustomMarginClasses) => {
	const mainContainer = document.getElementById('mainContent').firstElementChild.classList;
	mainContainer.remove(customClass);
};

export enum CustomMarginClasses {
	wide = 'wide',
	onlyLeft = 'only-left',
	onlyLeftMedium = 'only-left-medium',
	onlyLeftWide = 'only-left-wide',
	noBottom = 'no-bottom',
}
