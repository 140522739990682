import {
	Component,
	ViewChild,
	ViewChildren,
	Input,
	QueryList,
	ElementRef,
	OnDestroy,
	Output,
	EventEmitter,
	SimpleChanges,
	OnChanges,
	AfterViewInit,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { DomSanitizer } from '@angular/platform-browser';
import { Datasource, SizeStrategy, IDatasource } from 'ngx-ui-scroll';
import { CustomMenuOptionEvent } from '@trovata/app/shared/models/custom-menu.model';
import {
	ScrollableDataViewModel,
	ScrollableTableSortEvent,
	ScrollableTableClickEvent,
	Group,
	GroupOptionSelectedEvent,
} from '../../models/scrollable-data-table.model';
import { ScrollableDataViewService } from '../../services/scrollable-data-view.service';
import { GenericOption } from '@trovata/app/shared/models/option.model';

@Component({
	selector: 'app-scrollable-data-table',
	templateUrl: './scrollable-data-table.component.html',
	styleUrls: ['./scrollable-data-table.component.scss'],
})
export class ScrollableDataTableComponent implements OnDestroy, OnChanges, AfterViewInit {
	groupIDataSource: IDatasource;
	dataRowContainerHeight: string;
	scrollLeftValue: number;
	itemWidth: number;
	expandAllToggle: boolean;

	// dragging
	private draggingMouse: boolean;
	private startMouseX: number;

	mathObj: typeof Math;

	@Input() viewModel: ScrollableDataViewModel;
	@Input() clickableHeader: boolean;
	@Input() clickableLabel: boolean;
	@Input() refreshingData: boolean;
	@Input() disableMenus: boolean;
	@Input() disableExpandTotals: boolean;
	@Input() disableExpandAll: boolean;
	@Input() disableExpandProperties: boolean;
	@Input() editable: boolean;
	@Input() hideTable: boolean;
	@Input() sortable: boolean;
	@Input() disableVirtualScroll: boolean;

	@Output() sortedGroup: EventEmitter<ScrollableTableSortEvent>;
	@Output() itemClicked: EventEmitter<ScrollableTableClickEvent>;
	@Output() customMenuClicked: EventEmitter<CustomMenuOptionEvent<Group>> = new EventEmitter();
	@Output() groupOptionSelected: EventEmitter<GroupOptionSelectedEvent>;
	@Output() altChildrenIconClicked: EventEmitter<Group>;

	@ViewChildren('scroller') set scrollers(scrollers: QueryList<ElementRef>) {
		this._scrollers = scrollers;
		this.resetScrollers();
	}
	private _scrollers: QueryList<ElementRef>;
	@ViewChild('mainScroller') dateScroller: ElementRef<any>;
	@ViewChildren('expansionPanel') expansionPanels: QueryList<MatExpansionPanel>;
	@ViewChild('accordion') accordion: ElementRef;
	@ViewChild('scrollContainer') scrollContainer: ElementRef<any>;
	droppedItem: Subject<void>;

	private destroyed$: Subject<boolean> = new Subject();

	constructor(
		private scrollableDataViewService: ScrollableDataViewService,
		public sanitizer: DomSanitizer
	) {
		this.destroyed$ = new Subject<boolean>();
		this.sortedGroup = new EventEmitter();
		this.itemClicked = new EventEmitter();
		this.groupOptionSelected = new EventEmitter();
		this.altChildrenIconClicked = new EventEmitter<Group>();
		this.droppedItem = new Subject<void>();
		this.itemWidth = 145;
		this.scrollLeftValue = 0;
		this.dataRowContainerHeight = 'auto';
		this.mathObj = Math;
		this.clickableHeader = true;
		this.clickableLabel = true;
	}

	ngAfterViewInit(): void {
		this.setEventListeners();
	}

	private setEventListeners(): void {
		fromEvent(window, 'resize')
			.pipe(takeUntil(this.destroyed$))
			.subscribe(() => {
				this.scrollableDataViewService.detectChanges$.next();
			});
		fromEvent(this.scrollContainer.nativeElement, 'mousemove')
			.pipe(takeUntil(this.destroyed$))
			.subscribe((event: MouseEvent) => {
				this.handleMouseMove(event);
			});
		fromEvent(this.scrollContainer.nativeElement, 'mousedown')
			.pipe(takeUntil(this.destroyed$))
			.subscribe((event: MouseEvent) => {
				this.handleMouseDown(event);
			});
		fromEvent(this.scrollContainer.nativeElement, 'mouseleave')
			.pipe(takeUntil(this.destroyed$))
			.subscribe(() => {
				if (this.draggingMouse) {
					this.draggingMouse = false;
				}
			});
		fromEvent(this.scrollContainer.nativeElement, 'mouseup')
			.pipe(takeUntil(this.destroyed$))
			.subscribe(() => {
				if (this.draggingMouse) {
					this.draggingMouse = false;
				}
			});
		fromEvent(this.scrollContainer.nativeElement, 'wheel')
			.pipe(takeUntil(this.destroyed$))
			.subscribe((e: WheelEvent) => {
				if (Math.abs(e['wheelDeltaX']) > Math.abs(e['wheelDeltaY'])) {
					const scrollAmount: number = (e['wheelDeltaX'] / 120) * (this.itemWidth + 1);
					if (scrollAmount > 0) {
						this.scrollLeft(scrollAmount);
					} else {
						this.scrollRight(scrollAmount);
					}
					e.preventDefault();
				}
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.viewModel && changes.viewModel.currentValue) {
			this.expandAllToggle = false;
			this.setItemWidth();
			this.setTableHeight();
			if (this.viewModel.groupDisplayOrder.length >= 20 && !this.disableVirtualScroll) {
				this.activateVirtualScroll();
			}
		}
	}

	private setTableHeight(): void {
		if (this.viewModel.groupDisplayOrder.length > 12) {
			this.dataRowContainerHeight = 576 + 'px';
		} else {
			this.dataRowContainerHeight = 'auto';
		}
	}

	private setItemWidth(): void {
		this.itemWidth = Math.max(8 * this.viewModel.formatter.maxStringSize, 145) + (this.viewModel.extraCellWidth ?? 0);
	}

	private activateVirtualScroll(): void {
		this.createGroupIDataSource();
		// corrects padding for vscroll
		setTimeout(() => {
			this.groupIDataSource.adapter.check();
		}, 500);
	}

	private createGroupIDataSource(): void {
		const dataSource: IDatasource = new Datasource({
			get: (index: number, count: number, success): void => {
				const data: string[] = [];
				for (let i: number = index; i <= index + count - 1; i++) {
					if (this.viewModel.groupDisplayOrder[i] || this.viewModel.groupDisplayOrder[i] === '') {
						data.push(this.viewModel.groupDisplayOrder[i]);
					}
				}
				success(data);
			},
			settings: {
				startIndex: 0,
				padding: 2,
				minIndex: 0,
				maxIndex: this.viewModel.groupDisplayOrder.length - 1,
				sizeStrategy: SizeStrategy.Frequent,
			},
		});
		if (this.groupIDataSource) {
			this.groupIDataSource.settings = dataSource.settings;
			this.groupIDataSource.adapter.reset(dataSource);
		} else {
			this.groupIDataSource = dataSource;
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onDateScroll(): void {
		const left: number = this.dateScroller.nativeElement.scrollLeft;
		this._scrollers.forEach(scroller => {
			scroller.nativeElement.scrollLeft = left;
		});
		this.scrollLeftValue = left;
	}

	expandAll(): void {
		if (this.expandAllToggle === true) {
			this.expandAllToggle = false;
		} else {
			this.expandAllToggle = true;
		}
		this.viewModel.groupDisplayOrder.forEach((groupId: string) => {
			const group: Group = this.viewModel.groupData.groups[groupId];
			group.expanded = this.expandAllToggle;
			group.children.forEach((childGroupId: string) => {
				const childGroup: Group = group.childData.groups[childGroupId];
				if (childGroup.children?.length) {
					childGroup.expanded = this.expandAllToggle;
				}
			});
		});
	}

	private handleMouseDown(event: MouseEvent): void {
		if (!this.sortable) {
			this.draggingMouse = true;
			this.startMouseX = event.clientX;
		}
	}

	private handleMouseMove(event: MouseEvent): void {
		if (this.draggingMouse && !this.sortable) {
			const moveX: number = event.clientX - this.startMouseX;
			this.startMouseX = event.clientX;
			if (moveX > 0) {
				this.scrollLeft(moveX);
			} else if (moveX < 0) {
				this.scrollRight(moveX);
			}
		}
	}

	toggleCheck(): void {
		const expandedPanels: Element[] = this.accordion.nativeElement.querySelectorAll('.group-mat-expansion-panel.mat-expanded');
		if (!expandedPanels.length && this.expandAllToggle) {
			this.expandAllToggle = false;
		} else if (expandedPanels.length === this.viewModel.groupDisplayOrder.length && !this.expandAllToggle) {
			this.expandAllToggle = true;
		}
		this.scrollableDataViewService.detectChanges$.next();
	}

	private resetScrollers(): void {
		this._scrollers.forEach(scroller => {
			scroller.nativeElement.scrollLeft = this.scrollLeftValue;
		});
	}

	scrollRight(value?: number): void {
		let scrollValue: number;
		if (!value) {
			const scrollViewWidth: number = this.dateScroller.nativeElement.offsetWidth;
			scrollValue = this.itemWidth * Math.floor(scrollViewWidth / this.itemWidth) + Math.floor(scrollViewWidth / this.itemWidth);
		} else {
			scrollValue = Math.abs(value);
		}

		const currentLeft: number = this.dateScroller.nativeElement.scrollLeft;
		const newLeft: number = currentLeft + scrollValue;
		this.scrollLeftValue = newLeft;
		this.dateScroller.nativeElement.scrollLeft = newLeft;
		this._scrollers.forEach(scroller => {
			scroller.nativeElement.scrollLeft = newLeft;
		});
	}

	scrollLeft(value?: number): void {
		let scrollValue: number;
		if (!value) {
			const scrollViewWidth: number = this.dateScroller.nativeElement.offsetWidth;
			scrollValue = this.itemWidth * Math.floor(scrollViewWidth / this.itemWidth) + Math.floor(scrollViewWidth / this.itemWidth);
		} else {
			scrollValue = Math.abs(value);
		}
		const currentLeft: number = this.dateScroller.nativeElement.scrollLeft;
		const newLeft: number = currentLeft - scrollValue;
		this.dateScroller.nativeElement.scrollLeft = newLeft;
		this.scrollLeftValue = newLeft;
		this._scrollers.forEach(scroller => {
			scroller.nativeElement.scrollLeft = newLeft;
		});
	}

	drop(event: CdkDragDrop<string[]>): void {
		if (this.isDropAllowed(event)) {
			moveItemInArray(this.viewModel.groupDisplayOrder, event.previousIndex, event.currentIndex);

			if (this.groupIDataSource) {
				this.createGroupIDataSource();
			}
			this.sortedGroup.emit({ ids: [], isProperty: false });
			setTimeout(() => this.droppedItem.next(), 0);
		}
	}

	private isDropAllowed(event: CdkDragDrop<string[]>): boolean {
		const disabledItemIndex: number = this.viewModel.groupDisplayOrder.findIndex(groupId => !this.viewModel.groupData.groups[groupId].sortable);
		// Allow the drop only if the target index is less than or equal to the disabled item index
		return disabledItemIndex === -1 || event.currentIndex < disabledItemIndex;
	}

	onClick(
		clickableProperty: boolean,
		group: Group,
		date: string,
		usdMode: boolean,
		total: boolean = false,
		event: Event,
		expansionPanel?: MatExpansionPanel,
		propertyId?: string
	): void {
		event.stopPropagation();
		if (clickableProperty) {
			const data: ScrollableTableClickEvent = {
				date: date,
				groupIds: group.groupId ? [group.groupId] : [],
				group: group,
				usdMode: usdMode,
				total: total,
				headerProperty: propertyId,
			};
			if (propertyId) {
				data.groupIds.push(propertyId);
			}
			this.itemClicked.emit(data);
		} else {
			if (!this.disableExpandTotals) {
				expansionPanel.toggle();
			}
		}
	}

	onAltChildrenIconClick(group: Group): void {
		this.altChildrenIconClicked.emit(group);
	}

	onGroupOptionSelected(option: GenericOption, groupIndex: number): void {
		this.groupOptionSelected.emit({
			option: option,
			groupIndex: groupIndex,
		});
	}

	toggleBalancesExpand(): void {
		this.viewModel.groupData.balances.expanded = !this.viewModel.groupData.balances.expanded;
		setTimeout(() => {
			this.scrollableDataViewService.detectChanges$.next();
		}, 500);
	}

	toggleTotalsExpand(): void {
		this.viewModel.groupData.totals.expanded = !this.viewModel.groupData.totals.expanded;
		setTimeout(() => {
			this.scrollableDataViewService.detectChanges$.next();
		}, 500);
	}

	onCustomMenuClicked(event: CustomMenuOptionEvent<Group>): void {
		this.customMenuClicked.emit(event);
	}
}
