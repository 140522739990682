import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ScrollableDataViewService {
	detectChanges$: Subject<void> = new Subject();

	constructor() {}
}
