import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { LoaderConfig, SkeletonLoaderOptions, SkeletonLoaderUnit } from '../models/skeleton-loader.model';

@Directive({
	selector: '[skeletonLoader]',
})
export class SkeletonLoaderDirective implements OnChanges {
	@Input() skeletonLoader: boolean;
	@Input() loaderOptions: SkeletonLoaderOptions;

	private addSkeletonStyles: boolean;
	private removeSkeletonStyles: boolean;
	private mainStyleSheet: CSSStyleSheet;
	private insertedClassSelectorText: string;

	constructor(private element: ElementRef) {
		this.setMainStyleSheet();
	}

	private setMainStyleSheet() {
		const mainStyleSheetHref: string = `${document.location.origin}/styles.`;
		for (let i = 0; i < document.styleSheets.length; i++) {
			if (document.styleSheets[i].href && document.styleSheets[i].href.includes(mainStyleSheetHref)) {
				this.mainStyleSheet = document.styleSheets[i];
				break;
			}
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes && changes.skeletonLoader && changes.skeletonLoader.currentValue !== undefined) {
			if (changes.skeletonLoader.currentValue === true) {
				this.addSkeletonStyles = true;
				this.removeSkeletonStyles = false;
				this.addOrRemoveLoaderClass();
			} else if (changes.skeletonLoader.currentValue === false) {
				this.addSkeletonStyles = false;
				this.removeSkeletonStyles = true;
				this.addOrRemoveLoaderClass();
			}
		}
	}

	private addOrRemoveLoaderClass() {
		if (this.addSkeletonStyles && !this.removeSkeletonStyles) {
			this.checkToAddLoaderClass();
			if (this.loaderOptions) {
				this.checkToAddLoaderOptions();
			}
		} else if (!this.addSkeletonStyles && this.removeSkeletonStyles) {
			this.checkToRemoveLoaderClass();
			if (this.loaderOptions) {
				this.checkToRemoveLoaderOptions();
			}
		}
	}

	private checkToAddLoaderClass() {
		const loaderClass: string = this.loaderOptions && this.loaderOptions.loaderClass ? this.loaderOptions.loaderClass : LoaderConfig.loaderClass;
		if (!this.element.nativeElement.classList.contains(loaderClass)) {
			this.element.nativeElement.classList.add(loaderClass);
		}
	}

	private checkToAddLoaderOptions() {
		const units: SkeletonLoaderUnit = this.loaderOptions.units ? this.loaderOptions.units : LoaderConfig.units;
		const width: number | string = this.loaderOptions.width;
		const height: number | string = this.loaderOptions.height;
		const borderRadius: number | string = this.loaderOptions.borderRadius;
		const margin: string = this.loaderOptions.margin;
		const marginTop: string = this.loaderOptions.marginTop;
		const uniqueClass: string = `${LoaderConfig.loaderOptionClass}-${uuid()}`;
		this.insertedClassSelectorText = `.${uniqueClass}`;
		let classToInsert: string = `${this.insertedClassSelectorText} { `;
		if (width) {
			const widthToInsert: number | string = typeof width === 'number' ? `${width}${units}` : width;
			classToInsert = `${classToInsert} width: ${widthToInsert} !important;`;
		}
		if (height) {
			const heightToInsert: number | string = typeof height === 'number' ? `${height}${units}` : height;
			classToInsert = `${classToInsert} height: ${heightToInsert} !important;`;
		}
		if (borderRadius) {
			const borderRadiusToInsert: number | string = typeof borderRadius === 'number' ? `${borderRadius}${units}` : borderRadius;
			classToInsert = `${classToInsert} border-radius: ${borderRadiusToInsert} !important;`;
		}
		if (margin) {
			classToInsert = `${classToInsert} margin: ${margin} !important;`;
		}
		if (marginTop) {
			classToInsert = `${classToInsert} margin-top: ${marginTop} !important;`;
		}
		classToInsert = `${classToInsert}}`;
		this.mainStyleSheet.insertRule(classToInsert, 0);
		if (!this.element.nativeElement.classList.contains(uniqueClass)) {
			this.element.nativeElement.classList.add(uniqueClass);
		}
	}

	private checkToRemoveLoaderClass() {
		const loaderClass: string = this.loaderOptions && this.loaderOptions.loaderClass ? this.loaderOptions.loaderClass : LoaderConfig.loaderClass;
		if (this.element.nativeElement.classList.contains(loaderClass)) {
			this.element.nativeElement.classList.remove(loaderClass);
		}
	}

	private checkToRemoveLoaderOptions() {
		for (let i = 0; i < this.mainStyleSheet.cssRules.length; i++) {
			if (this.mainStyleSheet.cssRules[i].cssText.includes(LoaderConfig.loaderOptionClass)) {
				this.mainStyleSheet.deleteRule(i);
			}
		}
	}
}
