import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'combineStyles',
})
export class CombineStylesPipe implements PipeTransform {
	transform(style1: { [key: string]: string }, style2: { [key: string]: string }): { [key: string]: string } {
		return { ...style1, ...style2 };
	}
}
