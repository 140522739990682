<div class="control-buttons">
	<div *ngIf="visibleMenuSections.length > 0" matTooltipShowDelay="700" class="button-tooltip-wrapper" [matTooltip]="menuTooltip">
		<button mat-icon-button class="hover-button" aria-label="Change displayed columns" [matMenuTriggerFor]="preferencesMenu" [disabled]="menuDisabled">
			<mat-icon>tune</mat-icon>
		</button>
	</div>

	<button mat-icon-button class="hover-button" aria-label="Toggle grid mode" (click)="gridModeToggled()">
		<mat-icon>{{ gridMode ? 'grid_off' : 'grid_on' }}</mat-icon>
	</button>
</div>

<mat-menu #preferencesMenu>
	<ng-template matMenuContent *ngIf="visibleMenuSections.length > 0 && !menuDisabled">
		<div (click)="$event.stopPropagation()">
			<div *ngFor="let section of visibleMenuSections; let last = last">
				<div class="menu-section-content">
					<div *ngIf="section.title" class="menu-section-title menu-secondary-color">{{ section.title }}</div>

					<div *ngIf="section.onReorder; else notReordeable" cdkDropList (cdkDropListDropped)="rowOrderChanged($event, section)" class="menu-rows">
						<div class="menu-row" cdkDrag *ngFor="let row of section.rows">
							<mat-icon class="menu-row-drag-icon menu-secondary-color" cdkDragHandle>drag_indicator</mat-icon>
							<div class="menu-row-content">
								<div>{{ row.title }}</div>
								<div *ngIf="row.description" class="menu-secondary-color">{{ row.description }}</div>
							</div>
							<mat-slide-toggle color="primary" [checked]="row.checked" (change)="rowToggled($event, row)"> </mat-slide-toggle>
						</div>
					</div>

					<ng-template #notReordeable>
						<div class="menu-rows">
							<div class="menu-row" *ngFor="let row of section.rows">
								<div class="menu-row-content">
									<div>{{ row.title }}</div>
									<div *ngIf="row.description" class="menu-secondary-color">{{ row.description }}</div>
								</div>
								<mat-slide-toggle color="primary" [checked]="row.checked" (change)="rowToggled($event, row)"> </mat-slide-toggle>
							</div>
						</div>
					</ng-template>
				</div>

				<mat-divider *ngIf="!last" class="mt-15 mb-15"></mat-divider>
			</div>
		</div>
	</ng-template>
</mat-menu>
