import { Component, Input, ViewChild } from '@angular/core';
import { FlexGrid } from '@grapecity/wijmo.grid';
import { AnalysisDataViewModel } from '../../models/analysis-data-view.model';
import { AnalysisDataViewModelLegacy } from '../../models/analysis-data-view.legacy.model';

@Component({
	selector: 'app-analysis-grid',
	templateUrl: './analysis-grid.component.html',
	styleUrls: ['./analysis-grid.component.css'],
})
export class AnalysisGridComponent {
	@Input() viewModel: AnalysisDataViewModel | AnalysisDataViewModelLegacy;

	frozenColumns: number = 1;

	@ViewChild('flex') flex: FlexGrid;

	constructor() {}

	isNumber(value) {
		return !isNaN(value);
	}

	autoSizeColumns() {
		this.flex.autoSizeColumns();
	}
}
