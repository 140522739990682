import { trigger, transition, style, animate, state, query, AnimationTriggerMetadata } from '@angular/animations';

export const fadeIn: (duration?: number) => AnimationTriggerMetadata = (duration: number = 500) =>
	trigger('simpleFadeAnimation', [
		transition(':enter', [style({ opacity: 0 }), animate(duration, style({ opacity: 1 }))]),
		transition(':leave', [style({ opacity: 1 }), animate(0, style({ opacity: 1 }))]),
	]);

export const expandCollapse = trigger('expandCollapse', [
	transition(':enter', [style({ height: '0px', minHeight: '0' }), animate(300, style({ height: '*' }))]),
	transition(':leave', [style({ height: '*' }), animate(300, style({ height: '0px', minHeight: '0' }))]),
]);

export const expandCollapseHorizontal = trigger('expandCollapseHorizontal', [
	transition(':enter', [style({ width: '0px', minWidth: '0' }), animate('300ms ease-in', style({ width: '*' }))]),
	transition(':leave', [style({ width: '*' }), animate('300ms ease-out', style({ width: '0px', minWidth: '0' }))]),
]);

export const smoothHeight = trigger('grow', [
	transition('void <=> *', []),
	transition('* <=> *', [style({ height: '{{startHeight}}px', opacity: 0 }), animate('.5s ease')], {
		params: { startHeight: 0 },
	}),
]);

export const smoothMaxHeight = trigger('expand', [
	state('closed', style({ maxHeight: '0px' })),
	state('open', style({ maxHeight: '1200px' })),
	transition('closed => open', [animate('1200ms', style({ maxHeight: '1200px' }))]),
]);

export const smoothWidth = trigger('grow', [
	transition('void <=> *', []),
	transition('* <=> *', [style({ width: '{{startWidth}}px', opacity: 0 }), animate('.5s ease')], {
		params: { startWidth: 0 },
	}),
]);

export const smoothOpacity = trigger('fade', [
	state('out', style({ opacity: 0 })),
	state('in', style({ opacity: 1 })),
	transition('out => in', [animate('1200ms', style({ opacity: 1 }))]),
]);

export const slideDown = trigger('flyInOutDown', [
	// slide down from position
	transition('down => void', [animate(250, style({ transform: 'translateY(100%)' }))]),
	// slide down from void
	transition('void => down', [style({ transform: 'translateY(-100%)' }), animate(250)]),
]);

export const slideUp = trigger('flyInOutUp', [
	// slide up from position
	transition('up => void', [animate(250, style({ transform: 'translateY(-100%)' }))]),
	// slide up from void
	transition('void => up', [style({ transform: 'translateY(100%)' }), animate(250)]),
]);

// fade-in/fade-out animation
export const fadeInFadeOut = trigger('simpleFadeAnimation', [
	state('in', style({ opacity: 1 })),

	transition(':enter', [style({ opacity: 0 }), animate(500)]),

	transition(':leave', animate(300, style({ opacity: 0 }))),
]);

// slower fade in anaimation
export const slowFadeIn = trigger('slowFadeInAnimation', [
	state('in', style({ opacity: 1 })),

	transition(':enter', [style({ opacity: 0 }), animate(700)]),

	transition(':leave', animate(300, style({ opacity: 0 }))),
]);

export const dropInOut = trigger('dropInOut', [
	transition(':enter', [
		style({ opacity: 0, maxHeight: '0px' }), // apply default styles before animation starts
		animate('500ms ease-in-out', style({ opacity: 1, maxHeight: '500px' })),
	]),
	transition(':leave', [
		style({ opacity: 1, maxHeight: '500px' }), // apply default styles before animation starts
		animate('500ms ease-in-out', style({ opacity: 0, maxHeight: '0px' })),
	]),
]);

export const carouselSnackEnterExit = trigger('carouselSnackAnim', [
	transition(':enter', [
		query(
			'.snack-container',
			[style({ opacity: 0, transform: 'translateX(200px)' }), animate('300ms ease-in', style({ opacity: 1, transform: 'translateX(0)' }))],
			{ optional: true }
		),
	]),
	transition(':leave', [query('.snack-container', [animate('300ms ease-in', style({ opacity: 0, transform: 'translateX(200px)' }))], { optional: true })]),
]);

export const horizSplash = trigger('splashAnimation', [
	state(
		'idle',
		style({
			width: '0',
		})
	),
	state(
		'active',
		style({
			width: '200%',
		})
	),
	transition('idle => active', [animate('100ms ease-out')]),
	transition('active => idle', [animate('50ms ease-out')]),
]);
