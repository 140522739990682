export interface TableControlsMenuRow {
	title: string;
	description?: string;
	checked: boolean;
	onToggle: (state: boolean) => void;
}

export interface TableControlsMenuSection {
	title?: string;
	gridModeOnly?: boolean;
	rows: TableControlsMenuRow[];
	onReorder?: (previousIndex: number, currentIndex: number) => void;
}

export enum TableControlsConstants {
	gridModeOnly = 'Grid mode only',
	currency = 'Currency',
	convertedCurrency = 'Converted Currency',
	currencySymbols = 'Currency Symbols ($,€, £, ¥, etc.)',
	currencySymbolsDescription = 'Symbols in "Amount" column',
}
