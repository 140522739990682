<div class="grid-container">
	<wj-flex-grid
		#flex
		class="grid"
		id="hoverRow"
		[itemsSource]="viewModel.view"
		[stickyHeaders]="true"
		[allowSorting]="true"
		[copyHeaders]="true"
		[allowDragging]="false"
		[isReadOnly]="true"
		[allowMerging]="'Cells'"
		[frozenColumns]="viewModel.frozenGridColumns"
		(copying)="viewModel.wijmoCopyHandler(flex, $event)">
		<wj-flex-grid-column
			[allowMerging]="true"
			*ngFor="let groupBy of viewModel.groupByTypeDisplayValues; let index = index"
			[header]="groupBy"
			[binding]="groupBy">
		</wj-flex-grid-column>
		<wj-flex-grid-column [header]="'Type'" [binding]="'type'"> </wj-flex-grid-column>
		<wj-flex-grid-column *ngIf="viewModel.showGridCurrencyColumn" [header]="'Converted Currency'" [binding]="'currencyCode'"> </wj-flex-grid-column>
		<wj-flex-grid-column
			*ngFor="let date of viewModel.dateLabels?.length ? viewModel.dateLabels : viewModel.dateArray; let idx = index"
			[width]="145"
			[minWidth]="150"
			[header]="date"
			[binding]="viewModel.dateArray[idx]"
			width="2*"
			[cellTemplate]="viewModel.wijmoTemplate">
		</wj-flex-grid-column>
	</wj-flex-grid>
</div>
