import { Component, Input, SimpleChanges, ViewChildren, QueryList, ElementRef, OnChanges, EventEmitter, Output, OnDestroy, AfterViewInit } from '@angular/core';
import { CellStyle, Group, ScrollableSummaryColumn, ScrollableTableClickEvent, ScrollableTableSortEvent } from '../../models/scrollable-data-table.model';
import { ScrollableDataViewService } from '../../services/scrollable-data-view.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomMenuOption, CustomMenuOptionEvent } from '@trovata/app/shared/models/custom-menu.model';
import { Subject, takeUntil } from 'rxjs';
import { smoothHeight } from '@trovata/app/shared/utils/animations';

@Component({
	selector: 'app-scrollable-child-group',
	templateUrl: './scrollable-child-group.component.html',
	styleUrls: ['./scrollable-child-group.component.scss'],
	animations: [smoothHeight],
})
export class ScrollableChildGroupComponent implements OnChanges, OnDestroy, AfterViewInit {
	destroyed$: Subject<boolean>;
	@Input() group: Group;
	@Input() columnStyles: Record<string, CellStyle>;
	@Input() disableExpandProperties: boolean;
	@Input() disableMenus: boolean;
	@Input() dateArray: string[];
	@Input() summaryColumn: ScrollableSummaryColumn;
	@Input() clickableHeader: boolean;
	@Input() clickableLabel: boolean;
	@Input() nestedLevel: number;
	@Input() scrollLeft: number;
	@Input() itemWidth: number;
	@Input() width: number;
	@Input() editable: boolean;
	@Input() sortable: boolean;
	@Input() set droppedItem(dropped: Subject<void>) {
		if (dropped) {
			this.dropped = dropped;
			this.dropped.pipe(takeUntil(this.destroyed$)).subscribe(() => this.updateScroll());
		}
	}
	dropped: Subject<void>;

	@Output() sortedGroup: EventEmitter<ScrollableTableSortEvent>;
	@Output() customMenuClicked: EventEmitter<CustomMenuOptionEvent<Group>>;
	@Output() itemClicked: EventEmitter<ScrollableTableClickEvent>;
	@Output() altChildrenIconClicked: EventEmitter<Group>;

	@ViewChildren('scroller') set scrollers(scrollers: QueryList<ElementRef>) {
		this._scrollers = scrollers;
		this.updateScroll();
	}
	private _scrollers: QueryList<ElementRef>;

	constructor(
		private scrollableDataViewService: ScrollableDataViewService,
		public sanitizer: DomSanitizer
	) {
		this.destroyed$ = new Subject<boolean>();
		this.sortedGroup = new EventEmitter();
		this.itemClicked = new EventEmitter();
		this.customMenuClicked = new EventEmitter<CustomMenuOptionEvent<Group>>();
		this.altChildrenIconClicked = new EventEmitter<Group>();
		this.columnStyles = {};
	}

	ngAfterViewInit(): void {
		if (this._scrollers) {
			this.updateScroll();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.group) {
			setTimeout(() => {
				this.updateScroll();
			}, 0);
		}
		if (changes.scrollLeft && this._scrollers) {
			this.updateScroll();
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateScroll(): void {
		this._scrollers.forEach(scroller => {
			scroller.nativeElement.scrollLeft = this.scrollLeft;
		});
	}

	childClick(data: ScrollableTableClickEvent): void {
		if (data.groupIds) {
			data.groupIds.unshift(this.group.groupId);
		}
		this.itemClicked.emit(data);
	}

	onAltChildrenIconClick(group: Group): void {
		this.altChildrenIconClicked.emit(group);
	}

	onClick(
		clickableHeader: boolean,
		group: Group,
		date: string,
		usdMode: boolean,
		total: boolean = false,
		event: Event,
		childComponent: ScrollableChildGroupComponent,
		isChild?: boolean,
		forceOnClick?: boolean,
		headerProperty?: string
	): void {
		if (!forceOnClick) {
			event.stopPropagation();
		}
		if (clickableHeader || forceOnClick) {
			const data: ScrollableTableClickEvent = {
				groupIds: [group.groupId],
				group: group,
				date,
				usdMode,
				total: total,
				headerProperty,
			};
			if (isChild) {
				this.childClick(data);
				return;
			}
			this.itemClicked.emit(data);
		} else {
			if (isChild) {
				childComponent.toggleExpand();
			} else {
				this.toggleExpand();
			}
		}
	}

	toggleExpand(): void {
		this.group.expanded = !this.group.expanded;
		setTimeout(() => {
			this.scrollableDataViewService.detectChanges$.next();
		}, 500);
	}

	drop(event: CdkDragDrop<string[]>): void {
		moveItemInArray(this.group.children, event.previousIndex, event.currentIndex);
		this.sortedGroup.emit({ ids: [this.group.groupId], isProperty: false });
		setTimeout(() => this.dropped?.next(), 0);
	}

	childSortChanged(sortEvent: ScrollableTableSortEvent): void {
		sortEvent.ids.unshift(this.group.groupId);
		this.sortedGroup.emit(sortEvent);
	}

	onMenuItemClicked(opt: CustomMenuOption, group: Group): void {
		const event: CustomMenuOptionEvent<Group> = {
			option: opt,
			row: group,
		};
		this.customMenuClicked.emit(event);
	}
}
