<div class="w-100" cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListDisabled]="!group.sortableProperties || !sortable">
	<ng-container *ngFor="let property of displayProperties; let idx = index">
		<div
			*ngIf="!group.properties[property].hidden"
			class="flex flex-row w-100 h-100 hover-row"
			cdkDrag
			[cdkDragPreviewClass]="['cell-bottom-border', 'cell-top-border', 'cell-left-border', 'cell-right-border', 'scrollable-data-table-dragging']">
			<div
				class="title-item"
				[ngClass]="{
					'cursor-grab': group.properties[property]?.sortable && group.sortableProperties && sortable,
					subproperty: group.properties[property].subProperty,
				}"
				[style.width]="sanitizer.bypassSecurityTrustStyle('calc(' + 100.0 / (dateArray.length + 1) + '% - 36px)')"
				cdkDragHandle>
				<span
					class="title-text"
					[style.padding-left.px]="32 * nestedLevel"
					[ngClass]="{
						'clickable-label': (!group.sortableProperties || !sortable) && group.properties[property]?.clickableLabel,
						bold: group.headerProperties.includes(property) || group.properties[property].highlighted,
					}"
					(click)="
						(!group.sortableProperties || !sortable) && group.properties[property].clickableLabel
							? onClick(null, group.groupId, group.properties[property].isConvertedValue, group.properties[property].propertyId)
							: ''
					">
					{{ property }}
				</span>
				<ng-container *ngIf="group.properties[property].subProperty">
					<span
						class="title-text"
						[style.padding-left.px]="32 * nestedLevel"
						[style]="group.properties[property].subPropertyStyles"
						[ngClass]="{ 'clickable-label': (!group.sortableProperties || !sortable) && group.properties[property]?.clickableLabel }"
						(click)="
							(!group.sortableProperties || !sortable) && group.properties[property].clickableLabel
								? onClick(null, group.groupId, group.properties[property].isConvertedValue, group.properties[property].propertyId)
								: ''
						">
						{{ group.properties[property].subPropertyKey }}
					</span>
				</ng-container>
			</div>
			<div
				class="header-alignment-area flex cell-right-border cell-left-border"
				[style.width]="sanitizer.bypassSecurityTrustStyle('calc(' + (100.0 - 100.0 / (dateArray.length + 1)) + '% - 56px)')"
				[ngClass]="{ subproperty: group.properties[property].subProperty }">
				<div #scroller class="viewport" [style.width.px]="width">
					<ng-container *ngFor="let date of dateArray; let idx = index">
						<ng-template
							[ngTemplateOutlet]="dataCell"
							[ngTemplateOutletContext]="{ idx: idx, dataAccessor: date, property: property, isSummaryCol: false }"></ng-template>
					</ng-container>
					<ng-container *ngIf="summaryColumn">
						<ng-template
							[ngTemplateOutlet]="dataCell"
							[ngTemplateOutletContext]="{ idx: -1, dataAccessor: summaryColumn.dataKey, property: property, isSummaryCol: true }"></ng-template>
					</ng-container>
				</div>
			</div>
			<div class="scrollable-group-right-column"></div>
		</div>
	</ng-container>
</div>
<ng-template #dataCell let-idx="idx" let-dataAccessor="dataAccessor" let-property="property" let-isSummaryCol="isSummaryCol">
	<div
		class="flex-item"
		[style.minWidth.px]="itemWidth"
		[ngClass]="{
			'summary-column': isSummaryCol,
			'cell-right-border': dateArray && idx >= 0 && (idx !== dateArray.length - 1 || summaryColumn),
			'grey-null': group.properties[property].data[dataAccessor] === null,
			'red-delta': group.match && group.match[property] && group.match[property][dataAccessor] === false,
		}"
		[style]="columnStyles[dataAccessor]">
		<div [style]="group.propertyStylesByDate[dataAccessor]" class="w-100" [ngClass]="{ 'subproperty-right': group.properties[property].subProperty }">
			<div
				[style]="group.properties[property].propertyStylesByDate[dataAccessor]"
				(click)="
					group.properties[property].clickableValues
						? onClick(dataAccessor, group.groupId, group.properties[property]?.isConvertedValue, group.properties[property]?.propertyId, isSummaryCol)
						: ''
				"
				[ngClass]="{
					'sub-property-value-text': group.properties[property].subProperty,
					'value-text': !group.properties[property].subProperty,
					'clickable-value': group.properties[property].clickableValues,
					bold: (group.headerProperties.includes(property) || group.properties[property].highlighted) && group.properties[property].data[dataAccessor] !== null,
					'pt-12': group.properties[property].subProperty,
				}">
				{{ group.properties[property].data[dataAccessor] || group.placeholderValue }}
			</div>
			<div
				*ngIf="group.properties[property].subProperty"
				[style]="group.properties[property].subProperty[group.properties[property].subPropertyKey].propertyStylesByDate[dataAccessor]"
				[ngStyle]="group.properties[property].subPropertyStyles"
				(click)="
					group.properties[property].clickableValues
						? onClick(dataAccessor, group.groupId, group.properties[property]?.isConvertedValue, group.properties[property]?.subPropertyKey, isSummaryCol)
						: ''
				"
				[ngClass]="{
					'clickable-value': group.properties[property].clickableValues,
					'sub-property-value-text': group.properties[property].subProperty,
					'value-text': !group.properties[property].subProperty,
					'pb-12': group.properties[property].subProperty,
				}">
				{{ group.properties[property].subProperty[group.properties[property].subPropertyKey].data[dataAccessor] || group.placeholderValue }}
			</div>
		</div>
	</div>
</ng-template>
