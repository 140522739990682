import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { firstValueFrom, Observable } from 'rxjs';
import { Currency, CurrencyDict } from '../../models/currency.model';
import { PostCurrencyPreferences } from '../../store/actions/preferences.actions';
import { CurrencyState } from '../../store/state/currency/currency.state';
import { PreferencesState } from '../../store/state/preferences/preferences.state';

@Injectable({
	providedIn: 'root',
})
export class CurrencyFacadeService {
	@Select(CurrencyState.currencies) currencies$: Observable<Currency[]>;
	@Select(CurrencyState.currenciesDict)
	currenciesDict$: Observable<CurrencyDict>;
	@Select(PreferencesState.defaultCurrencyCode)
	defaultCurrencyCode$: Observable<string>;
	@Select(PreferencesState.userCurrencyCode)
	userCurrencyCode$: Observable<string>;
	@Select(PreferencesState.instanceCurrencyCode)
	instanceCurrencyCode$: Observable<string>;

	constructor(private store: Store) {}

	getCurrencies(): Promise<Currency[]> {
		return new Promise(async resolve => {
			this.currencies$.subscribe((currencies: Currency[]) => {
				resolve(currencies);
			});
		});
	}

	getCurrencyDict(): Promise<CurrencyDict> {
		return new Promise(resolve => {
			this.currenciesDict$.subscribe((currencies: CurrencyDict) => {
				if (currencies && Object.keys(currencies).length !== 0) {
					resolve(currencies);
				}
			});
		});
	}

	getCurrency(currencyCode: string): Promise<Currency> {
		return new Promise(async (resolve, reject) => {
			try {
				const currencies: CurrencyDict = await this.getCurrencyDict();
				const currency: Currency = currencies[currencyCode];
				resolve(currency);
			} catch (error) {
				reject(error);
			}
		});
	}

	getDefaultCurrency(): Promise<Currency> {
		return new Promise(async (resolve, reject) => {
			try {
				const defaultCurrencyCode: string = await this.getDefaultCurrencyCode();
				const currencies: CurrencyDict = await this.getCurrencyDict();
				const currency: Currency = currencies[defaultCurrencyCode];
				resolve(currency);
			} catch (error) {
				reject(error);
			}
		});
	}

	updateCurrencyPreferences(userCurrencyCode: string, instanceCurrencyCode: string): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new PostCurrencyPreferences(userCurrencyCode, instanceCurrencyCode)));
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	getDefaultCurrencyCode(): Promise<string> {
		return new Promise(resolve => {
			this.defaultCurrencyCode$.subscribe((defaultCurrencyCode: string) => {
				if (defaultCurrencyCode) {
					resolve(defaultCurrencyCode);
				}
			});
		});
	}

	getInstanceCurrencyCode(): Promise<string> {
		return new Promise(resolve => {
			this.instanceCurrencyCode$.subscribe((instanceCurrencyCode: string) => {
				if (instanceCurrencyCode) {
					resolve(instanceCurrencyCode);
				}
			});
		});
	}

	getUserCurrencyCode(): Promise<string> {
		return new Promise(resolve => {
			this.userCurrencyCode$.subscribe((userCurrencyCode: string) => {
				if (userCurrencyCode || userCurrencyCode === '') {
					resolve(userCurrencyCode);
				}
			});
		});
	}
}
