import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
	standalone: true,
	selector: 'trovata-loading-icon',
	templateUrl: './loading-icon.component.html',
	styleUrls: ['./loading-icon.component.scss'],
	imports: [CommonModule],
})
export class LoadingIconComponent {
	@Input() size = 2;
	@Input() customWidth;
	@Input() customHeight;
}
