import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TableControlsMenuSection, TableControlsMenuRow } from '../../models/table-controls.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
	selector: 'table-controls',
	templateUrl: './table-controls.component.html',
	styleUrls: ['./table-controls.component.scss'],
})
export class TableControlsComponent implements OnInit, OnChanges {
	@Input() gridMode: boolean;
	@Input() menuSections: TableControlsMenuSection[];
	@Input() menuDisabled: boolean;
	@Input() menuTooltip: string;

	@Output() emitGridModeToggled: EventEmitter<boolean>;

	visibleMenuSections: TableControlsMenuSection[];

	constructor() {
		this.gridMode = false;
		this.menuSections = [];
		this.menuDisabled = false;

		this.emitGridModeToggled = new EventEmitter<boolean>();
	}

	ngOnInit(): void {
		this.setVisibleMenuSections();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.menuSections) {
			this.setVisibleMenuSections();
		}
	}

	gridModeToggled(): void {
		this.gridMode = !this.gridMode;
		this.setVisibleMenuSections();
		this.emitGridModeToggled.emit(this.gridMode);
	}

	rowOrderChanged(event: CdkDragDrop<TableControlsMenuRow[]>, section: TableControlsMenuSection): void {
		moveItemInArray(section.rows, event.previousIndex, event.currentIndex);
		section.onReorder(event.previousIndex, event.currentIndex);
	}

	rowToggled(event: MatSlideToggleChange, row: TableControlsMenuRow): void {
		row.checked = event.checked;
		row.onToggle(row.checked);
	}

	private setVisibleMenuSections(): void {
		this.visibleMenuSections = this.menuSections.filter((section: TableControlsMenuSection) => (section.gridModeOnly ? this.gridMode : true));
	}
}
