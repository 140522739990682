import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TagOverlapTransactionMode } from '@trovata/app/features/reports/components/analysis-data-table/analysis-data-table.component';
import { PaginatedTableColumn } from '@trovata/app/shared/models/paginated-table-view-model';
import { SearchParameter } from '@trovata/app/shared/models/search-parameter.model';

interface DialogInterface {
	title: string;
	label: string;
	filter: string;
	tqlExpression: object;
	searchParameters: SearchParameter[];
	disableTagEdit: boolean;
	currencyConverted: string;
	tagOverlapMode?: TagOverlapTransactionMode;
	requestedColumns?: PaginatedTableColumn[];
}

@Component({
	selector: 'transactions-table-dialog',
	templateUrl: './transactions-table-dialog.component.html',
	styleUrls: ['./transactions-table-dialog.component.scss'],
})
export class TransactionsTableDialogComponent implements OnInit {
	tqlExpression: object;
	title: string;
	label: string;
	searchParameters: SearchParameter[];
	filter: string;
	transactionColumns?: PaginatedTableColumn[];
	currencyOverride: string;
	disableTagEdit: boolean;
	tagOverlapMode?: TagOverlapTransactionMode;

	constructor(
		public dialogRef: MatDialogRef<TransactionsTableDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogInterface
	) {}

	ngOnInit(): void {
		this.tqlExpression = this.data.tqlExpression;
		this.title = this.data.title;
		this.filter = this.data.filter;
		this.label = this.data.label;
		this.searchParameters = this.data.searchParameters || [];
		this.currencyOverride = this.data.currencyConverted;
		this.disableTagEdit = this.data.disableTagEdit;
		this.tagOverlapMode = this.data?.tagOverlapMode;
		if (this.data.requestedColumns?.length > 0) {
			this.transactionColumns = this.data.requestedColumns;
		}
	}
}
